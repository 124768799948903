import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_TESORERIA;

class Gastos
{
  obtener_gastos(options) {
    return axios.get(utils.url_options(`${baseURL}/tesoreria/gastos`, options));
  }

  contar_gastos_pagar() {
    return axios.get(`${baseURL}/tesoreria/gastos/total`);
  }

  obtener_gasto(id) {
    return axios.get(`${baseURL}/tesoreria/gastos/${id}`);
  }

  obtener_gastos_porpagar() {
    return axios.get(`${baseURL}/tesoreria/gastos/porpagar`);
  }

  generar_gastos(payload) {
    return axios.post(`${baseURL}/tesoreria/gastos`,payload);
  }

  agregar_observacion(id,payload) {
    return axios.put(`${baseURL}/tesoreria/gastos/${id}/observaciones`, payload);
  }

  descargar_documentos(id) {
    return axios.get(`${baseURL}/tesoreria/gastos/documentos/${id}`,{responseType: 'blob'});
  }

  actualiza_estatus(id, estatus) {
    return axios.put(`${baseURL}/tesoreria/gastos/${id}/estatus/${estatus}`);
  }

  obtener_autorizador() {
    return axios.get(`${baseURL}/tesoreria/autorizadores`);
  }

  obtener_gastos_autorizador(options) {
    return axios.get(utils.url_options(`${baseURL}/tesoreria/gastos/autorizadores`,options));
  }

  obtener_gastos_directivos(options) {
    return axios.get(utils.url_options(`${baseURL}/tesoreria/gastos/directivos`,options));
  }

  pagar_gastos(payload) {
    return axios.post(`${baseURL}/tesoreria/gastos/pagar_gastos`, payload);
  }

  pagar_gastos_externos(payload) {
    return axios.post(`${baseURL}/tesoreria/gastos/pagar_gastos_externos`, payload);
  }

  obtener_mis_gastos(options) {
    return axios.get(utils.url_options(`${baseURL}/tesoreria/gastos/mis-gastos`, options));
  }

  obtener_mis_gastos_id(id) {
    return axios.get(`${baseURL}/tesoreria/gastos/mis-gastos/${id}`);
  }

  descargar_documentos_all(id) {
    return axios.get(`${baseURL}/tesoreria/gastos/${id}/documentos`,{responseType: 'blob'});
  }
}

export default new Gastos()