<template>
  <Modal :options="{width: '50vw'}">
    <form @submit.prevent="agregar_pago">
      <div class="title">Pago externo</div>
      <div class="body">
        <div class="row form-group">
          <label for="gasto.monto" class="col-form-label col-sm-4">Gasto</label>
          <div class="col-sm-8"><input v-model="gasto.gasto" type="text" name="gasto.monto" id="gasto.monto" class="form-control" disabled></div>
        </div>
        <div class="row form-group">
          <label for="pago.banco" class="col-form-label col-sm-4">Banco</label>
          <div class="col-sm-8"><input v-model="pago.banco" type="text" name="pago.banco" id="pago.banco" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="gasto.monto" class="col-form-label col-sm-4">Gasto monto / Saldo pendiente</label>
          <div class="col-sm-8">
            <IC v-model="saldo_pendiente" label="$" id="gasto.monto" name="gasto.monto" :formats="['moneyFormat']" disabled/>
          </div>
        </div>
        <div class="row form-group">
          <label for="pago.monto" class="col-form-label col-sm-4">Monto a pagar</label>
          <div class="col-sm-8">
            <IC v-model="pago.monto" label="$" id="pago.monto" name="pago.monto" :formats="['moneyFormat']" @update="actualizar_saldos"/>
          </div>
        </div>
        <div class="row form-group">
          <label for="pago.saldo_pendiente" class="col-form-label col-sm-4">Saldo pendiente</label>
          <div class="col-sm-8">
            <IC v-model="pago.saldo_insoluto" label="$" id="pago.saldo_pendiente" name="pago.saldo_pendiente" :formats="['moneyFormat']" disabled/>
          </div>
        </div>
        <div class="row form-group">
          <label for="pago.cuenta" class="col-form-label col-sm-4">Cuenta / Clabe interbancaria</label>
          <div class="col-sm-8"><input v-model="pago.cuenta" type="text" name="pago.cuenta" id="pago.cuenta" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="pago.cuenta_contable" class="col-form-label col-sm-4">Cuenta contable</label>
          <div class="col-sm-8">
            <select v-model="pago.cuenta_contable" name="pago.cuenta_contable" id="pago.cuenta_contable" class="form-control">
              <option value="na">Selecciona una opción</option>
              <option v-for="opcion in catalogo.valores" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }} ({{ opcion.valor }})</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-4">Documentos</label>
          <div class="col-sm-8">
            <div class="row file_header">
              <div class="col-sm-8">Documento</div>
              <div class="col-sm-4">Opciones</div>
            </div>
            <template v-if="pago.archivos.length > 0">
            <div v-for="(archivo,index) in pago.archivos" :key="index" class="row mt-1 lista_archivos" >
              <div class="col-sm-8 my-auto">{{ archivo.name }}</div>
              <div class="col-sm-4 text-center"><button type="button" class="btn btn-primary" @click="quitar_archivo(index);">Quitar</button></div>
            </div>
            </template>
            <div v-else class="row">
              <div class="col-sm-12">No hay documentos</div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-4 offset-sm-8">
            <button type="button" class="btn complementary-btn" @click="$refs.seleccionar_archivo.click()">Agregar documento</button>
            <input class="file_hidden" type="file" ref="seleccionar_archivo" name="seleccionar_archivo" id="seleccionar_archivo" @change="agregar_documento">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn">Agregar</button></div>
          <div class="col-sm-3"><button type="button" class="btn danger-btn" @click="cerrar_pago">Cancelar</button></div>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script type="text/javascript">
  import IC from '@/components/Form/InputCompuesto'
  import Modal from '@/components/Modal'

  import api from '@/apps/tesoreria/api/gastos'
  import apiCatalogos from '@/apps/tesoreria/api/catalogos'

  export default {
    components: {
      Modal, IC
    }
    ,props: {
      gasto: {
        type: Object
        ,default: function() {
          return {
            uuid: null
            ,monto: 0
            ,pagos: [
              {
                monto: 0
                ,saldo_insoluto: 0
                ,cuenta_contable: 'na'
              }
            ]
          }
        }
      }
    }
    ,data: function() {
      return {
        pago: {
          banco: null
          ,monto: 0
          ,saldo_insoluto: 0
          ,cuenta: null
          ,archivos: []
          ,cuenta_contable: 'na'
        }
        ,saldo_pendiente: 0
        ,catalogo: {
          valores: []
        }
      }
    }
    ,mounted: function() {
      let total_pagado = parseFloat(this.gasto.monto);

      this.gasto.pagos.forEach(pago => {
        total_pagado -= parseFloat(pago.monto);
      });

      this.saldo_pendiente = total_pagado;
      this.pago.saldo_insoluto = this.saldo_pendiente;

      setTimeout(this.verificar_montos, 200);

      this.cargar_catalogo();
    }
    ,methods: {
      verificar_montos() {
        this.$log.info('verificar montos: ',this.gasto);
        if (this.gasto.a_pagar) {
          this.pago.monto = parseFloat(this.gasto.a_pagar);
          this.pago.saldo_insoluto = this.saldo_pendiente - this.pago.monto;
          this.$log.info('definimos monto: ', this.pago.monto);
        }
        
        if (this.gasto.cuenta_contable) {
          this.pago.cuenta_contable = this.gasto.cuenta_contable;
        }
      }
      ,cerrar_pago: function() {
        this.$emit('close')
      }
      ,cargar_catalogo: async function() {
        try {
          this.catalogo = (await apiCatalogos.buscar_catalogo('cuentas_contables')).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,actualizar_saldos: function() {
        this.$log.info('actualizar_saldos: ', this.pago.monto);
        if (this.pago.monto > this.saldo_pendiente) {

          setTimeout(() => {
            this.pago.monto = this.saldo_pendiente;
          }, 200);
          
          this.$log.info('actualizar_saldos2: ', this.pago.monto);
          return this.$helper.showMessage('Error','El monto del pago no puede ser mayor al saldo pendiente','error','alert');
        }

        let total_pago = parseFloat(this.pago.monto);

        this.gasto.pagos.forEach(pago => {
          total_pago += parseFloat(pago.monto);
        });

        this.pago.saldo_insoluto = (parseFloat(this.gasto.monto) - total_pago).toFixed(2);
      }
      ,agregar_documento: function() {
        if (this.$refs.seleccionar_archivo.files.length > 0) {
          let encontrado = false;

          this.pago.archivos.forEach(archivo => {
            if (archivo.name == this.$refs.seleccionar_archivo.files[0].name)
              encontrado = true;
          });

          if (encontrado)
            return this.$helper.showMessage('Error','El archivo "'+this.$refs.seleccionar_archivo.files[0].name+'" ya fue agregado','error','alert');

          this.pago.archivos.push(this.$refs.seleccionar_archivo.files[0]);

          this.$refs.seleccionar_archivo.value = null;
        }
      }
      ,quitar_archivo: function(index) {
        let archivos = [];

        for(let i=0; i<this.pago.archivos.length; i++) {
          if (i != index)
            archivos.push(this.pago.archivos[i]);
        }

        this.pago.archivos = archivos;
      }
      ,agregar_pago: async function() {
        try {
          let form = new FormData();
          form.append('gasto_id',this.gasto.id);
          form.append('banco',this.pago.banco);
          form.append('monto',this.pago.monto);
          form.append('cuenta',this.pago.cuenta);
          form.append('cuenta_contable', this.pago.cuenta_contable);
          
          this.pago.archivos.forEach(archivo => {
            form.append('archivos[]', archivo);
          })

          let res = (await api.pagar_gastos_externos(form)).data;
          this.$log.info('res',res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,watch: {
      pago: {
        handler: function(newVal, oldVal) {
          if (oldVal.monto != newVal.monto) {
            this.pago.saldo_insoluto = this.saldo_pendiente - newVal.monto;
          }
        }
        ,deep: true
      }
    }
  }
</script>

<style lang="scss">
  .file_header {
    background-color: #666666 !important;
    color: #fff !important;
    text-align: center;
    padding: 5px;
  }

  .file_hidden {
    display: none;
  }

  .lista_archivos {
    font-size: 0.9em;
  }

  .lista_archivos:nth-child(2n+1) {
    background-color: #E6E6E6 !important;
  }
</style>