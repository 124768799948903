import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_TESORERIA;

class Catalogo
{
  obtener_catalogos(options) {
    return axios.get(utils.url_options(`${baseURL}/catalogos`, options));
  }

  obtener_catalogo(id) {
    return axios.get(`${baseURL}/catalogos/${id}`);
  }

  buscar_catalogo(codigo) {
    return axios.get(`${baseURL}/catalogos/codigo/${codigo}`);
  }

  crear_catalogo(payload) {
    return axios.post(`${baseURL}/catalogos`, payload);
  }

  actualizar_catalogo(id, payload) {
    return axios.put(`${baseURL}/catalogos/${id}`, payload);
  }
}

export default new Catalogo()